/**
 * SERP
 *
 * @type pages
 */

// INDEX
@import "../../_core/styles/index";

// PLUGINS
@import "../../../../../../node_modules/slick-carousel/slick/slick.scss";
@import "../../../../../../node_modules/slick-carousel/slick/slick-theme.scss";

// WRAPPERS
@import "../../wrappers/default/default";

// ELEMENTS
@import "../../elements/button/button";
@import "../../elements/icon/icon";
@import "../../elements/image/image";
@import "../../elements/title/title";
@import "../../elements/price/price";
@import "../../elements/customSelect/customSelect";
@import "../../elements/orx-elem/arrow/arrow";
@import "../../elements/orx-elem/checkboxes/checkboxes";
@import "../../elements/loaderFull/loaderFull";

// WIDGETS
@import "../../components/orxapiwidgetanimation/widget/accordion/accordion";

// COMPONENTS
@import "../../components/header/header";
@import "../../components/breadcrumb/breadcrumb";
@import "../../components/headerNavBar/headerNavBar";
@import "../../components/error/error";
@import "../../components/footer/footer";
