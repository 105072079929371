/**
 * LOADER FULL
 *
 * @type element
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.elem-loader-full {
  @include position(fixed, 0 null null 0);
  @include orx-size(100%);
  background-color: rgba(255, 255, 255, 0.7); z-index: 1000;

  .loader-content {
    @include position(fixed, 50% null null 50%);
    @include orx-size(200px,120px);
    background-color: white; border-radius: 3px; text-align: center; box-shadow: 0 0 5px rgba(black, 0.3); z-index: 1001;
    transform: translate(-50%);

    .lds-ellipsis {
      @include orx-size(80px);
      margin-top: 20px; display: inline-block; position: relative;

      div {
        @include position(absolute, 33px null null auto);
        @include orx-size(13px);
        border-radius: 50%; background: $orx-brand-primary;
        animation-timing-function: cubic-bezier(0, 1, 1, 0);

        &:nth-child(1) {
          left: 8px;
          animation: lds-ellipsis1 0.6s infinite;
        }
        &:nth-child(2) {
          left: 8px;
          animation: lds-ellipsis2 0.6s infinite;
        }
        &:nth-child(3) {
          left: 32px;
          animation: lds-ellipsis2 0.6s infinite;
        }
        &:nth-child(4) {
          left: 56px;
          animation: lds-ellipsis3 0.6s infinite;
        }
      }
    }
  }
  .loader-content-prepackage {
    @include position(fixed, 80px null null 50%);
    @include orx-size(300px, 390px);
    background-color: white; border-radius: 3px; text-align: center; box-shadow: 0 0 5px rgba(black, 0.3); z-index: 1001;
    font-weight: bold;color: $orx-gray-6b; padding: 30px;
    transform: translate(-50%);
    .p1 { font-size: 20px; margin-bottom: 25px; }
    .p2 { margin: 0 0 2px 0; font-size: 14px; }
    .p3 { margin: 0 0 30px 0; font-size: 14px; }
    .logo-pp { text-indent: -4999px; background: url($orx-cssimgs-url + "logo-pp-lastminute.png") no-repeat; width: 181px; height: 22px;
      display: block; margin: 0 auto 0;
    }
    .lds-ellipsis {
      @include orx-size(80px, 65px);
      margin-top: -45px; display: inline-block; position: relative;

      div {
        @include position(absolute, 33px null null auto);
        @include orx-size(13px);
        border-radius: 50%; background: $orx-brand-primary;
        animation-timing-function: cubic-bezier(0, 1, 1, 0);

        &:nth-child(1) {
          left: 8px;
          animation: lds-ellipsis1 0.6s infinite;
        }
        &:nth-child(2) {
          left: 8px;
          animation: lds-ellipsis2 0.6s infinite;
        }
        &:nth-child(3) {
          left: 32px;
          animation: lds-ellipsis2 0.6s infinite;
        }
        &:nth-child(4) {
          left: 56px;
          animation: lds-ellipsis3 0.6s infinite;
        }
      }
    }
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }

}
/** ----------------------------------------------------------------
 * MEDIA QUERY
 */

@media #{$orx-mobile-first-md} {
  .elem-loader-full {
    .loader-content-prepackage {
      @include orx-size(800px, 280px);
    }
  }
}
