/**
 * FONTS
 *
 * @type specific
 */


/** ----------------------------------------------------------------
 * COMMON
 */

@font-face {
  font-family: "lastminute-icons";
  src:
    url("/lastminute/assets/fonts/icon-lastminute.svg") format("svg"),
    url("/lastminute/assets/fonts/icon-lastminute.ttf") format("ttf"),
    url("/lastminute/assets/fonts/icon-lastminute.woff") format("woff");
}