/**
 * CUSTOM SELECT
 *
 * @type element
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.elem-custom-select {
  background-color: white; border: 1px solid $orx-gray-df; border-radius: 3px;

  .select-input {
    @include orx-size(0);
    @include position(absolute, 0 null null 0);
    opacity: 0; margin: 0; z-index: -1; overflow: hidden; pointer-events: none;
  }
  .custom-select-input, .custom-select-date { height: 40px; display: flex; align-items: center; cursor: pointer; }
  .custom-select-input-txt {
    @include orx-size(100%,36px);
    font-weight: bold; color: $orx-brand-primary; font-size: 14px; padding: 10px 10px 10px 35px; display: block; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; position: relative;

    .elem-ff-icon {
      @include position(absolute, 50% null null 10px);
      color: $orx-gray-3c;
      transform: translateY(-50%);
    }
    .placeholder-style { color: $orx-gray-80; font-weight: $orx-font-weight-light; }
  }
  .custom-select-input-button {
    @include orx-size(90px,36px);
    font-weight: bold; color: $orx-gray-3c; font-size: 10px; display: flex; align-items: center; justify-content: center;
  }
  .custom-select-panel {
    @include position(fixed, 55px null null 0);
    @include orx-size(calc(100% - 14px));
    background-color: white; border-radius: 3px; margin: 7px; overflow: hidden; z-index: 102;

    .title-block {
      background-color: $orx-brand-primary; color: white; height: 53px; padding: 10px; border-radius: 3px 3px 0 0; position: relative;

      .label-block {
        .label { font-size: 16px; font-weight: $orx-font-weight-semi-bold; text-transform: uppercase; margin-bottom: 4px; }
        .legend {
          font-size: 10px; font-weight: $orx-font-weight-light;

          strong { font-weight: $orx-font-weight-semi-bold; font-size: 12px; }
        }
      }
      .icon-wrap {
        @include position(absolute, 50% 5px null null);
        @include orx-size(37px);
        background-color: rgba(black, 0.3); cursor: pointer;
        transform: translateY(-50%);

        .elem-ff-icon {
          @include position(absolute, 50% null null 50%);
          font-size: 18px;
          transform: translate(-50%, -50%);
        }
      }
    }
    .custom-select-panel-content {
      background-color: $orx-wrapper-full-gray; padding: 10px 10px 60px 10px; overflow-y: auto;

      &:after {
        @include position(absolute, null null 0 0);
        @include orx-size(100%,50px);
        content: ""; z-index: 102;
        background: -moz-linear-gradient(top, rgba(white, 0) 0%, rgba(white, 1) 76%, rgba(white, 1) 99%);
        background: -webkit-linear-gradient(top, rgba(white, 0) 0%, rgba(white, 1) 76%, rgba(white, 1) 99%);
        background: linear-gradient(to bottom, rgba(white, 0) 0%, rgba(white, 1) 76%, rgba(white, 1) 99%);
      }
      .panel-content-item,  {
        background-color: white; color: $orx-gray-3c; font-size: 16px; font-weight: $orx-font-weight-light; margin-bottom: 10px; height: 56px; padding: 0 15px; border: 1px solid $orx-gray-df; border-radius: 3px; cursor: pointer; display: flex; align-items: center;

        &.selected {
          border: 1px solid $orx-brand-primary; font-weight: $orx-font-weight-bold; color: $orx-brand-primary;
        }
        &:last-child { margin-bottom: 0; }
      }
      .travel-input-div {
        position: relative;

        .travel-input {
          background-color: white; color: $orx-brand-primary; font-size: 16px; font-weight: $orx-font-weight-light; margin-bottom: 10px; outline: none; width: 100%; height: 56px; padding: 0 50px 0 45px; border: 1px solid $orx-gray-df; border: 1px solid $orx-brand-primary; border-radius: 3px; cursor: pointer; display: flex; align-items: center;

          &:hover { font-weight: $orx-font-weight-bold; }
        }
        .travel-input-icon {
          color: #f2007d; position: absolute; bottom: 36%; font-weight: bold !important;
          &.left { left: 20px; }
          &.right { right: 20px; }
        }
      }
      .passenger-number-container {
        color: $orx-gray-3c; margin-bottom: 15px; position: relative;
        .spinner-content {
          display: flex;
          .bt-label {
            font-size: 16px; width: 50%;

            .legend { font-size: 12px; font-weight: $orx-font-weight-light; margin-top: 4px; display: block; }
          }
          .spinner-block {
            display: inline-flex; justify-content: flex-end; width: 50%; text-align: right;

            .spinner-button {
              @include orx-size(40px);
              background-color: $orx-gray-f1; border: 1px solid $orx-gray-df; display: inline-flex; align-items: center; justify-content: center; cursor: pointer;
            }
            .spinner-remove {}
            .spinner-add {

            }
            .icon-wrap {
              @include orx-size(20px);
              font-size: 16px; color: $orx-brand-primary; border: 1px solid $orx-brand-primary; border-radius: 25px; display: flex; align-items: center; justify-content: center;
            }
            .spinner-value {
              @include orx-size(53px, 40px);
              background-color: white; text-align: center; font-weight: $orx-font-weight-bold; font-size: 16px; border: 0 none; border-top: 1px solid $orx-gray-df; border-bottom: 1px solid $orx-gray-df;
            }
          }
        }
      }
      &.passenger {
        .btn-wrap {
          @include position(fixed, null null 0 0);
          @include orx-size(100%,auto);
          background-color: $orx-gray-3c; padding: 10px; text-align: center; z-index: 200;
        }
      }
      .age-children-selection {
        border-top: 1px solid $orx-gray-df; margin-top: 20px; padding-top: 20px; position: relative;

        .children-item {
          background-color: $orx-gray-f1; padding: 10px; margin-bottom: 15px;

          .row {
            margin: 0 -10px;

            .col-4 { padding: 0 10px; }
          }
          .label { font-size: 16px; font-weight: $orx-font-weight-light; text-transform: none; margin-bottom: 7px; color: $orx-gray-3c !important; }
          .select-wrap {
            position: relative;

            .input-style {
              @include orx-size(100%,40px);
              background-color: white; color: $orx-gray-3c; border: 1px solid $orx-gray-df; padding: 0 5px;
            }
          }
        }
      }
    }
  }

  &.panel-right {
    .custom-select-panel {
      @include position(fixed, 55px auto null 0);
    }
  }

  &.dark-style {
    background-color: $orx-gray-31; border: 1px solid $orx-gray-08;

    .custom-select-input-txt {
      color: white;

      .elem-ff-icon { color: white; }
    }
    .custom-select-input-button { color: white; }
  }
}


/** ----------------------------------------------------------------
 * MEDIA QUERY
 */

@media #{$orx-mobile-first-md} {
  .elem-custom-select {
    .custom-select-panel { top: 85px; }
  }
}

@media #{$orx-mobile-first-lg} {
  .elem-custom-select {
    position: relative;

    .custom-select-panel {
      @include position(absolute, -85px null null -468px);
      @include orx-size(436px,auto);
      top: -85px !important;

      .custom-select-panel-content {
        height: 580px !important;

        .panel-content-item {
          &:hover { border: 1px solid $orx-brand-primary; font-weight: $orx-font-weight-bold; color: $orx-brand-primary; }
        }
        &.passenger {
          color: $orx-gray-3c; font-size: 16px; font-weight: $orx-font-weight-light;
          .btn-wrap {
            @include position(relative, null null null null);
            @include orx-size(100%,auto);
            background-color: $orx-wrapper-full-gray; padding: 10px; text-align: center; z-index: 200;
          }
        }
      }
    }
    &.panel-right {
      .custom-select-panel {
        @include position(absolute, -58px -435px null auto);
        width: 400px;
      }
    }
  }
}

/*====== Fade out close effect ======*/
@keyframes fade-out-animate {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}


/*====== Fade in open effect ======*/
@keyframes fade-in-animate {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/*====== Fade in on top open effect ======*/
@keyframes fade-in-top {
  0% {
    opacity: 0;
    transform: translateY(-40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/*====== Fade out on down close effect ======*/
@keyframes fade-out-down-animate {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(40px);
  }
}

@keyframes fadeOutUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-40px);
  }
}

/*====== Fade out on up close effect ======*/
@keyframes fade-out-up-animate {
  0% {
    opacity: 0;
    transform: translateY(-40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in-top {
  animation: fade-in-top 500ms ease-in-out;
  top: 55px;
}

.fade-in-animate {
  animation: fade-in-animate 200ms ease-in-out;
}

.fade-out-animate {
  animation: fade-out-animate 200ms ease-in-out;
}

.fade-out-down-animate {
  animation: fade-out-down-animate 200ms ease-in-out;
}

.fade-out-up-animate {
  animation: fade-out-up-animate 200ms ease-in-out;
}

.fadeOutUp {
  animation: fadeInUp 200ms ease-in-out;
}
