/**
 * VARIABLES
 *
 * @type specific
 */

/** ----------------------------------------------------------------
 * COMMON
 */

/*! CATALOG */
$orx-assets-url: "/lastminute/assets/" !default;
$orx-core-url: "/lastminute/_core/" !default;
$orx-cssimgs-url: $orx-core-url + "css_imgs/" !default;
$orx-font-url: $orx-assets-url + "fonts/" !default;

/** ----------------------------------------------------------------
 * COLOR
 */

// BRAND
$orx-brand-primary: #f2007d !default;
$orx-brand-primary-darker: #d3007d !default;
$orx-brand-secondary: #930053 !default;
$orx-brand-tertiary: #333332 !default;
$orx-brand-quaternary: #b2b2b2 !default;

// BASE
$orx-white: #fff !default;
$orx-light-white: #ffffff67 !default;
$orx-black: #000 !default;

// DEFAULT BASE GRAY
$orx-gray: #5f5f5f !default;
$orx-gray-8: #888 !default;
$orx-gray-3: #333 !default;

// SPECIFIC GRAY
$orx-gray-c4: #c4c4c4 !default;
$orx-gray-fa: #fafafa !default;
$orx-gray-f1: #f1f1f1 !default;
$orx-gray-ea: #eaeaea !default;
$orx-gray-ed: #ededed !default;
$orx-gray-e4: #e4e4e4 !default;
$orx-gray-e2: #e2e2e2 !default;
$orx-gray-3c: #3c3c3c !default;
$orx-gray-31: #313131 !default;
$orx-gray-cf: #cfcfcf !default;
$orx-gray-df: #dfdfdf !default;
$orx-gray-08: #080808 !default;
$orx-gray-80: #808080 !default;
$orx-gray-dc: #dcdcdc !default;
$orx-gray-e9: #e9ebee !default;
$orx-gray-d0: #d0d0d0 !default;
$orx-gray-e6: #e6e6e6 !default;
$orx-gray-c9: #c9c9c9 !default;
$orx-gray-af: #afafaf !default;
$orx-gray-de: #dedede !default;
$orx-gray-6b: #6b6b6b !default;
$orx-gray-78: #787878 !default;
$orx-gray-a5: #a5a5a5 !default;
$orx-gray-ffe: #ffecf7 !default;


// SPECIFIC OTHER COLORS
$orx-semi-transparent-white: rgba(255, 255, 255, 0.1);
$orx-quarter-transparent-white: rgba(255, 255, 255, 0.25);
$orx-semi-transparent-grey: #7771;
$orx-linear-gradient-flash-sales: linear-gradient(to right, rgb(160,0,99), rgb(242, 0, 125));

// FONT COLOR
$orx-brand-font-color-primary: $orx-black !default;
$orx-brand-font-color-secondary: #676767 !default;

// PROMO TYPE
// TODO add specific if you want!

// SPECIFIC
$orx-footer-primary: #192333 !default;
$orx-footer-secondary: #151f2c !default;
$orx-order-confirmation: #2ba0dd !default;
$orx-order-waiting: #f7941d !default;
$orx-order-ongoing: #a67c52 !default;
$orx-lightblue: #00b0db !default;
$orx-lightyellow: #fdc62f !default;
$orx-preheader-text-shadow: #1b2e1b !default;
$orx-lightgreen: #92c140 !default;
$orx-yellow-fed: #fede88 !default;
$orx-yellow-flash-sale: #ffb511 !default;
$orx-oney-green: #84bd06 !default;

// MESSAGE
$orx-message-info: #01a4e6 !default;
$orx-message-success: #18a701 !default;
$orx-message-warning: #ffce4c !default;
$orx-message-error: #e00042 !default;
$orx-message-promo: #b60036 !default;

// BORDER
$orx-border-default-color: #ceccce !default;
$orx-border-primary-color: $orx-brand-primary !default;
$orx-border-light-color: #d5d7d8 !default;

// WRAPPER
$orx-wrapper-full-gray: #f9f8f8 !default;

/** ----------------------------------------------------------------
 * TYPE
 */

// FAMILY
$orx-font-family-base: arial, sans-serif !default;
// custom primary
$orx-font-family-primary: "Open Sans", $orx-font-family-base !default;
$orx-font-family-secondary: "Tw Cen MT Condensed" !default;
// handwritten
$orx-font-family-handwritten: "BrownPen" !default;
// icon
$orx-font-family-icon: "lastminute-icons" !default;

// FONT WEIGHT
$orx-font-weight-light: 300 !default;
$orx-font-weight-regular: 400 !default;
$orx-font-weight-semi-bold: 600 !default;
$orx-font-weight-bold: 700 !default;
$orx-font-weight-black: 900 !default;

/** ----------------------------------------------------------------
 * SPACING
 */

// BORDER
$orx-border-base-radius: 8px !default;
$orx-border-small-radius: 4px !default;
$orx-border-button-radius: 0 !default;

// GUTTER
$orx-gutter-base: 30px !default;
$orx-gutter-half-width: 30px / 2 !default;

// TEXT SHADOW
$orx-text-shadow: 0 3px 49px $orx-preheader-text-shadow;

/** ----------------------------------------------------------------
 * LAYOUT
 */

// Grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1370px
);

// Grid containers
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 991px,
  xl: 1370px
);

// MEDIA QUERY
$orx-mobile-first-sm: "only screen and (min-width: #{map-get($grid-breakpoints, "sm")})" !default;  // min 576px
$orx-mobile-first-md: "only screen and (min-width: #{map-get($grid-breakpoints, "md")})" !default;  // min 768px
$orx-mobile-first-lg: "only screen and (min-width: #{map-get($grid-breakpoints, "lg")})" !default;  // min 991px
$orx-mobile-first-xl: "only screen and (min-width: #{map-get($grid-breakpoints, "xl")})" !default;  // min 1370px

@media #{$orx-mobile-first-xl} {
  .wrap-component {
    // CONTAINER FLUID
    .inner-wrap-component { max-width: #{map-get($container-max-widths, "xl")}; }
  }
}
