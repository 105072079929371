/**
 * ARROW ORXAPI WIDGET ANIMATION
 *
 * $type element
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.elem-orx-arrow {
  @include orx-size(11px,auto);
  display: inline-block;
  transition: all .3s ease-in-out;

  &:before,
  &:after {
    @include orx-size(10px,2px);
    background-color: #909090; border-radius: 25px; content: "";
    transition: all .3s ease-in-out;
  }

  &:before {
    @include position(absolute, auto null null 50%);
    margin-left: 4px;
    transform: rotate(45deg);
  }

  &:after {
    @include position(absolute, auto -15px null null);
    transform: rotate(-45deg);
  }

  .open & {
    &:before,
    &:after { background-color: $orx-brand-primary; }
    &:before { transform: rotate(-45deg); }
    &:after { transform: rotate(45deg); }
  }
}
