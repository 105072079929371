/**
 * TITLE
 *
 * @type element
 */


/** ----------------------------------------------------------------
 * COMMON
 */

.elem-title {
  color: $orx-brand-primary; font-size: 24px; font-weight: $orx-font-weight-bold; padding: 20px 0 15px; margin: 0;
}


/** ----------------------------------------------------------------
 * MEDIA QUERY
 */

@media #{$orx-mobile-first-md} {
  .elem-title { font-size: 30px; }
}
