/**
 * STYLES
 *
 * $type variable
 */

$orx-checkboxes-center-top: -3px;
$orx-checkboxes-size: 22px;
$orx-checkboxes-animation-ripple: 700ms;
$orx-checkboxes-animation-check: 0.2s;
$orx-checkboxes-light-bg-text: rgba(black, 0.84);
$orx-checkboxes-color-border: $orx-gray-80;
$orx-checkboxes-checked-color: $orx-brand-primary;
$orx-checkboxes-checked-color-border: $orx-brand-secondary;
$orx-checkboxes-center-left: 8px;
