/**
 * SHADOW
 *
 * @type specific
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.shadow-box { box-shadow: 0 2px 8px rgba($orx-black, 0.12); }