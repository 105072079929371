/**
 * DIAPORAMA
 *
 * $type component
 */


/** ----------------------------------------------------------------
 * COMMON
 */

.cpt-error {
  margin: 20px;
  .title {font-weight: $orx-font-weight-bold; font-size: 20px;}

}
