/**
 * HEADING
 *
 * @type specific
 */


/** ----------------------------------------------------------------
 * COMMON
 */

h1, h2, h3,
h4, h5, h6 { font-weight: normal; }


h3 {
  font-size: 18px; text-transform: uppercase; font-weight: bold;
}
