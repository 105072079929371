/// Mixin printing a linear-gradient
/// as well as a plain color fallback
/// and the `-webkit-` prefixed declaration
/// @access public
/// @param {Keyword | Angle} $direction - Linear gradient direction
/// @param {Arglist} $color-stops - List of color-stops composing the gradient

/// Test if `$value` is a valid direction
/// @param {*} $value - Value to test
/// @return {Bool}
@function orx-is-direction($value) {
  $is-keyword: index((to top, to top right, to right top, to right, to bottom right, to right bottom, to bottom, to bottom left, to left bottom, to left, to left top, to top left), $value);
  $is-angle: type-of($value)=='number' and index('deg' 'grad' 'turn' 'rad', unit($value));
  @return $is-keyword or $is-angle;
}

/// Convert a direction to legacy syntax
/// @param {Keyword | Angle} $value - Value to convert
/// @require {function} is-direction
/// @require {function} convert-angle
/// @throw Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be a direction.;
@function orx-legacy-direction($value) {
  @if orx-is-direction($value)==false {
    @error "Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be a direction.";
  }
  $conversion-map: ( to top: bottom, to top right: bottom left, to right top: left bottom, to right: left, to bottom right: top left, to right bottom: left top, to bottom: top, to bottom left: top right, to left bottom: right top, to left: right, to left top: right bottom, to top left: bottom right);
  @if map-has-key($conversion-map, $value) {
    @return map-get($conversion-map, $value);
  }
  @return 90deg - $value;
}

@mixin orx-linear-gradient($direction, $color-stops...) {
  // Direction has been omitted and happens to be a color-stop
  @if orx-is-direction($direction)==false {
    $color-stops: $direction, $color-stops;
    $direction: 180deg;
  }

  background: nth(nth($color-stops, 1), 1);
  background: -webkit-linear-gradient(orx-legacy-direction($direction), $color-stops);
  background: linear-gradient($direction, $color-stops);
}


// Text linear gradient
@mixin orx-text-linear-gradient($from, $to, $stop: 100%, $direction: left) {
  background: linear-gradient(to $direction, $from, $to, $stop);
  background: -webkit-linear-gradient(to $direction, $from, $to, $stop );
  background: -moz-linear-gradient(to $direction, $from, $to, $stop );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
