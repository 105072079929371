/**
 * BUTTON
 *
 * @type element
 */


/** ----------------------------------------------------------------
 * COMMON
 */

.elem-button {
  display: inline-flex; align-items: center; justify-content: center; border: none; cursor: pointer; position: relative;

  &--default {
    background-color: $orx-brand-primary; color: $orx-white; padding: 14px 35px; font-size: 16px; font-weight: $orx-font-weight-semi-bold; border-radius: 3px;

    .elem-ff-icon { margin-left: 15px; position: relative; display: block; }
  }

  // SIZES VARIANTS
  &--sm { height: 40px; border-radius: 20px; min-width: 140px; }
  &--md { height: 50px; border-radius: 25px; min-width: 150px; }

  // COLORS VARIANTS
  &--primary { background-color: transparent; color: $orx-white; font-weight: bold; padding: 10px 40px; border: 2px solid white; border-radius: 25px; }
  &--secondary { background-color: transparent; color: $orx-brand-secondary; font-weight: bold; padding: 10px 20px; border: 2px solid $orx-brand-secondary; border-radius: 25px; }

  &.upper {
    text-transform: uppercase;
  }
}