/**
 * ACCORDION ORXAPI WIDGET ANIMATION
 *
 * $type component
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.cpt-orx-accordion {
  margin: 0; position: relative;

  .orx-accordion-title {
    background-color: $orx-gray-e9; font-size: 14px; font-weight: bold; text-transform: uppercase; margin: 0; padding: 13px 0 13px 15px; cursor: pointer; position: relative;

    .elem-orx-arrow {
      @include position(absolute, 50% 24px null null);
      transform: translateY(-50%); display: block;
    }
  }
  .orx-accordion-mask {
    height: 0; overflow: hidden; position: relative;
    transition: height .400s cubic-bezier(1, 0.09, 0.54, 0.63);
  }
  .orx-accordion-content {
    padding: 15px; background-color: white; line-height: 20px;

    p { margin: 0 0 10px; }
  }
  .orx-accordion-container {
    padding: 0; border-bottom: 1px solid $orx-gray-d0;

    &.open {
      .orx-accordion-mask { height: auto; }
      .orx-accordion-title {
        background-color: $orx-brand-primary; color: white;

        .elem-orx-arrow {
          &:before,
          &:after { background-color: white; }
        }
      }
    }
  }

}

/** ----------------------------------------------------------------
 * MEDIA QUERY
 */
@media #{$orx-mobile-first-md} {
  .cpt-orx-accordion {
    .orx-accordion-title {
      padding: 0; margin-bottom: 10px; cursor: default;

      .elem-orx-arrow { display: none; }
    }
    .orx-accordion-content { padding: 0; }
    .orx-accordion-container { padding: 0; border-bottom: 0 none; }
    .orx-accordion-mask { height: auto !important; }
  }
}
