/**
 * BREADCRUMB
 *
 * @type component
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.cpt-breadcrumb {
  padding: 15px 0; position: relative;

  .sep { margin: 0 4px; }
  .link-style { color: $orx-gray-3c; text-decoration: underline; }
  .user-info{display: none; position: absolute; right: 0; margin-left: 50px; font-weight: $orx-font-weight-semi-bold ;
    .text{margin-right: 8px;}
    .logout{float: right; margin-top: -8px;}
    .button-wrap .elem-button--default { font-size: 12px; padding: 9px 10px;}
  }

}

/** ----------------------------------------------------------------
 * MEDIA QUERY
 */

@media #{$orx-mobile-first-md} {
  .cpt-breadcrumb { }
}
