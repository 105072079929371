/**
 * HEADER NAV BAR
 *
 * @type component
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.cpt-header-nav-bar {
  @include position(fixed, 55px null null 0);
  @include orx-size(100%,auto);
  background-color: white; box-shadow: 0 0 0 rgba(black, 0); display: flex; justify-content: center; z-index: 2;
  transition: all 0.4s;

  .mobile-app & { top: 0; }

  .nav-bar-content {
    width: 100%; display: flex; justify-content: center; align-items: center;

    .nav-bar-item {
      @include orx-size(calc(100% / 3), 40px);
      display: flex; justify-content: center; align-items: center; cursor: pointer;
      transition: all 0.1s ease-out;

      &:first-child { border-left: 0 none; border-right: 1px solid $orx-gray-e9; }
      &:last-child { border-left: 1px solid $orx-gray-e9; border-right: 0 none; }
      .icon-wrap { margin-right: 7px; }
      .label { font-size: 12px; font-weight: $orx-font-weight-bold; }
      &.active { background-color: $orx-brand-primary; color: white; }
    }
  }

  &.fixed {
    .scroll-down & { transform: none; }
    .scroll-up & { box-shadow: 0 0 0 rgba(black, 0); transform: none; }
  }

  .scroll-down & { transform: translate3d(0, -100%, 0); }
  .scroll-up & { box-shadow: 0 0 10px rgba(black, 0.2); transform: none; }
}

/** ----------------------------------------------------------------
 * MEDIA QUERY
 */

@media #{$orx-mobile-first-lg} {
  .cpt-header-nav-bar-product { background-color: transparent; }
}
