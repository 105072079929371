/**
 * CHECKBOXES ORX
 *
 * $type element
 */

@import "styles";

/** ----------------------------------------------------------------
 * COMMON
 */

.elem-orx-checkboxes {
  position: relative;

  .elem-orx-checkboxes-label {
    display: grid; grid-template-columns: min-content auto; grid-gap: 0.5em; font-size: 2rem;

    .checkbox--control {
      @include orx-size(20px);
      border-radius: 0; border: 1px solid #cbcbcb; display: inline-grid;

      svg { transition: transform 0.1s ease-in 25ms; transform: scale(0); transform-origin: bottom left; }
    }
    .orx-checkboxes-input {
      display: grid; grid-template-areas: "checkbox";

      &.radio {
        .checkbox--control { border-radius: 100%; }
      }
      > * { grid-area: checkbox; }
      .input-style {
        @include orx-size(1em);
        opacity: 0;

        &:focus + .checkbox--control { }
        &:checked + .checkbox--control svg { transform: scale(0.7); position: relative; top: -3px; left: 3px; }
        &:checked + .checkbox--control { background-color: $orx-brand-primary; border: 1px solid $orx-brand-primary; }
        &:disabled + .checkbox--control { color: #959495; }
      }
    }
  }

  .orx-checkboxes-label { display: flex; flex-direction: row; }

  .label-txt { padding: 0 45px 0 10px; width: 100%; display: flex; align-items: center; }
  .label-number { font-size: 11px; }

  // Disabled
  &.disabled {
    color: $orx-gray-dc;

    .elem-orx-checkboxes-label {
      .checkbox--control { border-color: $orx-gray-dc; }
    }
  }
}