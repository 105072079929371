/**
 * IMAGE
 *
 * @type element
 */

@import "style";

/** ----------------------------------------------------------------
 * COMMON
 */

.elem-image {
  margin: 0; position: relative;


  .border-animate {
    @include position(absolute, null null 0 0);
    @include orx-size(2px,0);
    background-color: rgba(black, 0.1); opacity: 0;
    transition: all 0.2s ease-in-out 0s;

    &.top {
      @include position(absolute, 0 auto auto 0);
      @include orx-size(0,2px);
      transition-duration: 0.6s;
    }
    &.right {
      @include position(absolute, 0 0 auto auto);
      transition-duration: 1s;
    }
    &.bottom {
      @include position(absolute, auto 0 0 auto);
      @include orx-size(0,2px);
      transition-duration: 1.4s;
    }
  }

  img {
    @include orx-size(100%);
    background-color: $orx-image-bgc; vertical-align: top; object-fit: cover; display: block;
  }

  .overlay-image {
    @include orx-size(100%);
    @include position(absolute, 0 null null 0);
    background-color: rgba(#000, 0.4);

    &.overlay-white { background-color: rgba(#fff, 0.4); }
  }
}
