/**
 * DEFAULT
 *
 * @type wrappers
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.wrap-component {
  .container-fluid { padding: 0; }
}

/** ----------------------------------------------------------------
* SPECIFIC
*/

// FULL BACKGROUND WHITE
.wrap-full-white {
  background-color: $orx-white;

  .inner-wrap-component { padding-left: 0; padding-right: 0; }
}

// FULL BACKGROUND GRAY
.wrap-full-gray {
  background-color: $orx-wrapper-full-gray;

  .inner-wrap-component { background-color: $orx-wrapper-full-gray; padding-left: 0; padding-right: 0; }
}

// FULL BACKGROUND PRIMARY
.wrap-full-primary {
  background-color: $orx-brand-primary; color: white;

  .inner-wrap-component { background-color: $orx-brand-primary; padding-left: 15px; padding-right: 15px; }
}

// FULL BACKGROUND SECONDARY
.wrap-full-secondary {
  background-color: $orx-brand-secondary; color: white;

  .inner-wrap-component { background-color: $orx-brand-secondary; padding-left: 15px; padding-right: 15px; }
}

// FULL BACKGROUND TRANSPARENT
.wrap-full-transparent { background-color: transparent; }

// FULL CONTAINER
.wrap-full-container {
  .inner-wrap-component { padding-left: 0; padding-right: 0; }
}

// FULL CONTAINER & CHILDREN
.wrap-full-container-children {
  .inner-wrap-component { padding-left: 0; padding-right: 0; max-width: 100%; }
  .container-fluid { padding-left: 0; padding-right: 0; }
}

/** ----------------------------------------------------------------
* MEDIA QUERY
*/

@media #{$orx-mobile-first-sm} {
  .wrap-component {
    .container { max-width: unset; }
  }
}

@media #{$orx-mobile-first-md} {
  // FULL BACKGROUND WHITE
  .wrap-full-white {
    .inner-wrap-component { padding-left: 15px; padding-right: 15px; }
  }
  // FULL BACKGROUND GRAY
  .wrap-full-gray {
    .inner-wrap-component { padding-left: 15px; padding-right: 15px; }
  }
}

@media #{$orx-mobile-first-lg} {
  .wrap-full-container {
    .inner-wrap-component { padding-left: 15px; padding-right: 15px; }
  }
}

@media #{$orx-mobile-first-xl} {
  .wrap-component {
    // CONTAINER FLUID
    .inner-wrap-component { max-width: #{map-get($container-max-widths, "xl")}; }
  }

  .wrap-full-container-children {
    .inner-wrap-component { max-width: 100%; }
  }
}
