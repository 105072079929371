/**
 * HEADER
 *
 * @type component
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.cpt-header {
  @include position(fixed, 0 null null 0);
  background-color: $orx-brand-primary; color: white; width: 100%;
  box-shadow: 0 2px 4px 0 rgba(black,0.25); z-index: 999; margin-bottom: 8px;

  &.fixed {
    @include position(fixed, 0 null null 0);
    background-color: rgba(black,0.9); color: white; z-index: 999;
  }

  .logo-header {
    @include position(absolute, 50% null null 15px);
    max-height: 60px;
    transform: translateY(-50%);
  }

  &.logo-center {
    .logo-header {
      @include position(absolute, 50% null null 50%);
      transform: translateY(-50%) translateX(-50%);
    }
  }

  &.logo-right {
    .logo-header {
      @include position(absolute, 50% 15px null auto);
      transform: translateY(-50%);
    }
  }
}

/** ----------------------------------------------------------------
 * MEDIA QUERY
 */

@media #{$orx-mobile-first-lg} {
  .cpt-header { position: relative; }
}
