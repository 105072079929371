/**
 * PRICE
 *
 * @type element
 */


/** ----------------------------------------------------------------
 * COMMON
 */

.elem-price {
  position: relative;

  .offer { font-size: 10px; }
  .price-block {
    .price { font-size: 20px; font-weight: $orx-font-weight-bold; margin-right: 8px; }
    .mention { font-size: 10px; }
  }
  .base-price { color: $orx-gray; text-decoration: line-through; }
}


/** ----------------------------------------------------------------
 * MEDIA QUERY
 */

@media #{$orx-mobile-first-md} {
  .elem-price { }
}
