/**
 * FOOTER
 *
 * $type component
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.cpt-footer {
  height: 650px; font-size: 16px; font-weight: $orx-font-weight-bold; padding-top: 40px; position: relative;
}
